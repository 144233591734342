<template>
  <Spinner v-if="isLoading" />
  <div v-else>
    <div class="page__block mb-30">
      <VButtonPageBack />

      <div v-if="isDetail" class="additional">
        <VButton
          v-if="permissionUpdateTemplateStageLine"
          text="Редактировать"
          icon="edit"
          size="sm"
          @click.native="goToPage('TemplateStageLineUpdate', { id })"
        />

        <VButton
          v-if="permissionDestroyTemplateStageLine"
          text="Удалить"
          icon="remove"
          size="sm"
          @click.native="openModal"
        />
      </div>
    </div>

    <VForm
      v-model="formData"
      class="gap-m"
      @request="templateStageLineSaveHandler"
    >
      <h3 v-if="isDetail" class="fw-normal">
        Цепочка этапов:&nbsp;{{formData.name}}
      </h3>

      <VInput
        v-else
        name="name"
        label="Название цепочки этапов"
        width="450px"
        required
      >
        <Validation for="required"/>
      </VInput>

      <div class="column gap-m">
        <FormStageLineItem
          v-for="(item, i) in statuses"
          :key="i"
          :index="i"
          :stageItem="item"
          ref="stageItemRef"
          @deleteItem="deleteStage(i)"
          :disabled="isDetail"
          :isNotRequiredName="false"
        />
      </div>

      <div v-show="!isDetail" class="column gap-m">
        <VButton
          text="Добавить этап"
          icon="create"
          size="sm"
          color="primary-sm"
          @click.native="addStage(statuses.length + 1)"
        />

        <ButtonGroup
          :textBtnConfirm="isEdit ? 'Сохранить' : 'Создать'"
          textBtnReject="Отмена"
          btn-size="xl"
          :loading="$store.state.isSendingRequestPage"
          @reject="goToList"
        />
      </div>
    </VForm>

    <ModalConfirmation
      v-if="isModal"
      @confirm="templateStageLineDeleteHandler"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup'
import FormStageLineItem from '@/components/production/stage/FormStageLineItem'
import VForm from '@/components/Form/VForm'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import VInput from '@/components/Form/Vinput/VInput'
import Validation from '@/components/Form/Validation'
import VButton from '@/components/simple/button/VButton'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'

import { mixinModal } from '@/mixins/modal/mixinModal'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { defaultStageList, StageItemModel } from '@/classes/StageItemModel'
import { hasRepeatStage } from '@/utils/pages/Production/stageLine/hasRepeatStage'
import { createStageLineFormData } from '@/utils/pages/Production/stageLine/createStageLineFormData'
import { goToPage } from '@/utils/router'
import { isValidStageLine } from '@/utils/pages/Production/stageLine/isValidStageLine'
import { getUserItem } from '@/utils/model/User'
import { showAlertWarning } from '@/utils/store/alert'

export default {
  name: 'TemplateStageLine',
  components: {
    ButtonGroup,
    ModalConfirmation,
    VForm,
    FormStageLineItem,
    Validation,
    VButton,
    VButtonPageBack,
    VInput
  },
  mixins: [mixinModal],
  props: {
    action: String
  },

  data () {
    return {
      isDetail: false,
      isCreate: false,
      isEdit: false,

      id: null,
      statuses: [],

      formData: {
        name: ''
      }
    }
  },
  computed: {
    ...mapState('productions/template/stageLine', {
      item: 'item',
      isLoading: 'isLoading'
    }),
    ...mapGetters('permission', {
      permissionUpdateTemplateStageLine: 'permissionUpdateTemplateStageLine',
      permissionDestroyTemplateStageLine: 'permissionDestroyTemplateStageLine'
    })
  },
  watch: {
    action () {
      this.changeAction()
    }
  },
  async mounted () {
    this.id = parseInt(this.$route.params.id)
    this.changeAction()

    if (this.id) {
      await this.loadItem(this.id)
    }

    if (this.isDetail || this.isEdit) {
      this.syncData()
    }

    if (this.isCreate) {
      this.statuses = [...defaultStageList]
    }
  },

  methods: {
    ...mapMutations('productions/template/stageLine', {
      DELETE_ALL: 'DELETE_ALL'
    }),
    ...mapActions('productions/template/stageLine', {
      loadItem: 'loadItem',
      createItem: 'createItem',
      updateItem: 'updateItem',
      deleteItem: 'deleteItem',
      syncAll: 'syncAll'
    }),
    goToPage,

    changeAction () {
      if (this.action === 'detail') {
        this.isEdit = false
        this.isDetail = true
      }
      if (this.action === 'create') {
        this.isCreate = true
      }
      if (this.action === 'update') {
        this.isDetail = false
        this.isEdit = true
      }
    },

    syncData () {
      this.formData.name = this.item?.name
      this.statuses = this.item?.statuses.map(item => ({
        ...item,
        user: getUserItem(item.user)
      }))
    },

    async templateStageLineSaveHandler () {
      if (!isValidStageLine(this.$refs.stageItemRef)) return

      const stageLineFormData = createStageLineFormData(this.$refs.stageItemRef)

      if (hasRepeatStage(stageLineFormData)) {
        return showAlertWarning('Нельзя, чтобы на линии этапов повторялись этапы. Измените линию этапов или создайте новый этап в справочнике.')
      }

      const data = {
        name: this.formData.name,
        statuses: stageLineFormData
      }

      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_PAGE')

        const response = this.isCreate ?
          await this.createItem({ data }) : await this.updateItem({ id: this.id, data })
        if (response?.status !== 200 && response?.status !== 201) return

        this.id = response.data?.id
        await this.loadItem(this.id)
        this.syncData()

        // обновление главного массива с шаблонами
        this.syncAll({
          data: this.item,
          isCreate: this.isCreate
        })

        await goToPage('TemplateStageLineDetail', { id: this.id })
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_PAGE', false)
      }
    },

    addStage (index) {
      this.statuses.splice(this.statuses.length - 3, 0, new StageItemModel(index))
    },

    deleteStage (i) {
      this.$delete(this.statuses, i)
    },

    async templateStageLineDeleteHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const response = await this.deleteItem(this.id)
        if (response?.status !== 204) return

        // удаление из массива с шаблонами
        this.DELETE_ALL(this.id)
        this.goToList()
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    goToList () {
      goToPage('TemplateStageLineList')
    }
  }
}
</script>
