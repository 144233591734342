<template>
  <VButton
    text="Назад"
    block="page-back"
    size="init"
    icon="arrow-back"
    @click.native="onClick"
    @click.middle.native="onClick"
  />
</template>

<script>
import VButton from '@/components/simple/button/VButton'
import { goToPrevPage, goToPage } from '@/utils/router'

export default {
  components: { VButton },
  props: {
    toPageName: {
      type: String,
      required: false,
      default: ''
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    callback: {
      type: [Function, Boolean],
      required: false,
      default: false
    }
  },
  methods: {
    onClick () {
      if (this.callback) {
        return this.callback()
      }

      if (this.toPageName) {
        return goToPage(this.toPageName, this.params)
      }

      goToPrevPage()
    }
  }
}
</script>
